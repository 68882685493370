/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  InjectionToken,
  OnInit,
  Optional,
  SkipSelf,
  computed,
  contentChild,
  contentChildren,
  inject,
  input,
  model,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { outputToObservable, takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ExportService } from '@mca/shared/util';
import { PrimeTemplate, SortEvent, SharedModule, SortMeta } from 'primeng/api';
import { debounceTime, map, skip, Subject, switchMap, tap, timer } from 'rxjs';
import { GridColumnFormatService } from './grid-column-format.service';
import { GridColumnService } from './grid-columns.service';
import {
  GridBodyCellDefaultDirective,
  GridBodyCellDirective,
  GridFootCellDefaultDirective,
  GridFootCellDirective,
  GridHeadCellDefaultDirective,
  GridHeadCellDirective,
  GridRowExpansionDirective,
} from './template-directives';
import { CellClickEvent, ColumnConfig, ColumnParams, defaultGridPagination, GridPaginationParams } from './types';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { RouterLink } from '@angular/router';
import { NgStyle, NgTemplateOutlet, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

const DefaultSize = 6;

export interface RowChanges {
  [field: string]: { tooltip?: string };
}

const columnServiceToken = new InjectionToken<GridColumnService<any>>('grid-column-service');
export const gridColumnServiceFactory = (hostService: GridColumnService<any>, selfService: GridColumnService<any>) =>
  hostService || selfService;

@Component({
  selector: 'lib-shared-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GridColumnService,
    {
      provide: columnServiceToken,
      useFactory: gridColumnServiceFactory,
      deps: [[new SkipSelf(), new Optional(), GridColumnService], GridColumnService],
    },
  ],
  standalone: true,
  imports: [TooltipModule, DropdownModule, FormsModule, TableModule, SharedModule, NgStyle, NgTemplateOutlet, NgClass, RouterLink],
})
export class GridComponent implements OnInit {
  private gridColumnService = inject(columnServiceToken);
  private columnFormatService = inject(GridColumnFormatService);
  private exportService = inject(ExportService);
  private destroyRef = inject(DestroyRef);

  loading = signal<boolean>(false);
  changes = signal<RowChanges[]>([]);
  changeCount = signal<number[]>([]);
  changeTooltip = signal<string[]>([]);
  totals = signal<{ [key: string]: number }>({});

  data = input.required<any[] | null>();
  tableValue = computed(() => this.data() || []);
  private tableValue$ = toObservable(this.tableValue);
  columnConfig = input.required<ColumnConfig<any>>();
  private columnConfig$ = toObservable(this.columnConfig);

  /**
   * optional inputs
   */
  idField = input<string>('');
  header = input<string>();
  // name enables column presets feature
  name = input<string>('');
  sortField = input<string>('');
  sortOrder = input<number>(1);
  sortMode = input<'single' | 'multiple'>('single');
  multiSortMeta = input<SortMeta[]>([]);
  scrollable = input<boolean>(true);
  vScroll = input<boolean>(false);
  rowHeight = input<number>(80);
  defaultScrollHeight = computed(() => this.rowHeight() * DefaultSize + 'px');
  scrollHeight = input<string>(this.defaultScrollHeight());
  headless = input<boolean>(false);
  showFooter = input<boolean>(true);
  // enables csv export
  exportEnabled = input<boolean>(true);
  exportName = input<string>('');
  colWidth = input<string>('100px');
  fixedColWidth = input<boolean>(false);
  rowClassFunc = input<(row: any) => string>(() => '');
  styleClass = input<string>('');
  selection = model<any[]>([]);
  selectedRows = output<any[]>();
  selectionMode = input<'single' | 'multiple' | null | undefined>(null);
  enableCheckboxSelection = input<boolean>(false);
  pagination = model<GridPaginationParams>(defaultGridPagination);
  paginationChanged = output<GridPaginationParams>();
  externalSearchTrigger = input<Subject<void> | undefined>(undefined, { alias: 'search' });
  skipInitialSearch = input<boolean>(false);
  searchTrigger = output<void>({ alias: 'search' });
  hideSearchButton = input<boolean>(false);
  private searchTrigger$ = outputToObservable(this.searchTrigger);
  private combinedSearchTrigger$ = toObservable(computed(() => this.externalSearchTrigger() || this.searchTrigger$)).pipe(
    switchMap(t => t),
  );
  cellClick = output<CellClickEvent>();

  headCells = contentChildren<PrimeTemplate>(GridHeadCellDirective);
  defaultHeadCell = contentChild<PrimeTemplate>(GridHeadCellDefaultDirective);
  bodyCells = contentChildren<PrimeTemplate>(GridBodyCellDirective);
  defaultBodyCell = contentChild<PrimeTemplate>(GridBodyCellDefaultDirective);
  footCells = contentChildren<PrimeTemplate>(GridFootCellDirective);
  defaultFootCell = contentChild<PrimeTemplate>(GridFootCellDefaultDirective);
  rowExpansion = contentChild<PrimeTemplate>(GridRowExpansionDirective);
  ptable = viewChild<any>(Table);

  private columns$ = this.gridColumnService.columns$.pipe(
    map(columns =>
      Object.values(columns)
        .filter(v => v.show ?? true)
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0)),
    ),
    debounceTime(0),
  );
  columns = toSignal(this.columns$, { initialValue: [] });
  selectedPreset = toSignal(this.gridColumnService.selectedPreset$);
  presets = toSignal(this.gridColumnService.presets$);

  minimumScrollHeight = computed(() => {
    if (this.loading() || this.tableValue().length === 0) {
      return '0';
    }
    if (!this.vScroll()) {
      return null;
    }
    if (this.tableValue().length < DefaultSize && this.scrollHeight() === this.defaultScrollHeight()) {
      return this.rowHeight() * this.tableValue().length + 'px';
    }
    return this.scrollHeight();
  });

  ngOnInit() {
    this.loading.set(!this.skipInitialSearch());
    this.columnConfig$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(config => {
      this.gridColumnService.setColumns(config);
      if (this.name()) {
        this.gridColumnService.syncWithStorage(this.name());
      }
    });
    this.syncSearchTriggers();
    this.initDataLoad();
    if (!this.skipInitialSearch()) {
      // ensure trigger has been subscribed in parent already
      timer(0)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.searchTrigger.emit());
    }
  }

  changePagination(p: TableLazyLoadEvent) {
    const page = (p.first ?? 0) / (p.rows ?? 1);
    const pageSize = p.rows;
    const sortField = p.sortField;
    const sortOrder = p.sortOrder;
    const paginationChanged = this.pagination().page !== page || this.pagination().pageSize !== pageSize;
    const sortChanged =
      String(this.pagination().sortField) !== String(sortField) ||
      (this.pagination().sortOrder && sortOrder && +(this.pagination()?.sortOrder ?? 0) !== +sortOrder);
    if (!(paginationChanged || sortChanged)) {
      return;
    }
    this.loading.set(true);
    this.pagination.set({ ...this.pagination(), page, pageSize: pageSize ?? 1, sortField, sortOrder });
    this.paginationChanged.emit(this.pagination());
  }

  formatValue(rowData: any, columnParams: ColumnParams<any>) {
    const value = columnParams.value?.(rowData) ?? rowData[columnParams.field ?? ''];
    if (columnParams.format) {
      return this.columnFormatService.format(value, columnParams.format, columnParams.formatOptions);
    }
    return value;
  }

  configureColumns() {
    this.gridColumnService.openColumnsConfig();
  }

  selectPreset(name: string) {
    this.gridColumnService.selectPreset(name);
  }

  removePreset() {
    this.gridColumnService.removePreset();
  }

  trackRowChange = (index: number, row: any) => (this.idField ? row[this.idField()] : row['id'] ?? index);

  trackColumnChange = (index: number, column: ColumnParams<any>) => column.field;

  getHeadCell(name: string) {
    return this.headCells().find(v => v.name === name) || this.defaultHeadCell();
  }

  getBodyCell(name: string) {
    return this.bodyCells().find(v => v.name === name) || this.defaultBodyCell();
  }

  getFootCell(name: string) {
    return this.footCells().find(v => v.name === name) || this.defaultFootCell();
  }

  export() {
    const columns = this.columns().filter(column => !column.skipExport);
    const exportData = this.tableValue().map((row: any) =>
      columns.reduce((acc, column) => {
        const field = column.field ?? '';
        const value = this.formatExportValue(row, column);
        return Object.assign(acc, { [field]: value });
      }, {}),
    );
    const header = columns.map(column => column.label || column.field) as string[];
    this.exportService.exportToCsv(exportData, `${this.exportName() || this.name() || 'export'}.csv`, header);
  }

  /**
   * event event.data - Data to sort
   * event.mode - 'single' or 'multiple' sort mode
   * event.field - Sort field in single sort
   * event.order - Sort order in single sort
   * event.multiSortMeta - SortMeta array in multiple sort
   */
  customSort(event: SortEvent) {
    const multiSortMeta = event.multiSortMeta;

    if (multiSortMeta && multiSortMeta.length > 0) {
      event.data?.sort((data1, data2) => {
        for (const meta of multiSortMeta) {
          const result = this.compareValues(data1, data2, meta.order, meta.field);
          if (result !== 0) {
            return result;
          }
        }
        return 0;
      });
    } else if (event.field && event.order) {
      event.data?.sort((data1, data2) => {
        return this.compareValues(data1, data2, event.order, event.field);
      });
    }
  }

  private compareValues(data1: any, data2: any, order = 1, field?: string): number {
    if (!field) {
      return 0;
    }
    const fieldConfig = this.columnConfig()[field] ?? ({} as ColumnParams<any>);
    const value1 = fieldConfig.value?.(data1) ?? data1[field];
    const value2 = fieldConfig.value?.(data2) ?? data2[field];
    let result = 0;

    if (fieldConfig.sort) {
      result = fieldConfig.sort(data1, data2);
    } else if (value1 == null && value2 != null) {
      result = -1;
    } else if (value1 != null && value2 == null) {
      result = 1;
    } else if (value1 == null && value2 == null) {
      result = 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    }

    return order * result;
  }

  getStyleClass(styleClass: string | ((row: any) => string), row: any) {
    return typeof styleClass === 'function' ? styleClass(row) : styleClass;
  }

  rowSelection() {
    this.selectedRows.emit(this.selection());
  }

  private syncSearchTriggers() {
    if (this.externalSearchTrigger()) {
      // proxy internal search trigger to external
      this.searchTrigger$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.externalSearchTrigger()?.next();
      });
    }
  }

  private initDataLoad() {
    this.combinedSearchTrigger$
      .pipe(
        tap(() => this.loading.set(true)), // skip current value and wait for update
        switchMap(() => this.tableValue$.pipe(skip(1))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(data => {
        const changes = data.map(row => this.getRowChanges(row));
        this.changes.set(changes);
        this.changeCount.set(changes.map(row => Object.keys(row).length));
        this.changeTooltip.set(
          changes.map(row =>
            Object.keys(row)
              .map(field => this.columnConfig()[field].originalMessage?.(row) || this.columnConfig()[field].label)
              .map(message => `<p class="mt-1 mb-1">${message}</p>`)
              .join(''),
          ),
        );
        this.totals.set(
          data.reduce((acc, row) => {
            Object.keys(this.columnConfig()).forEach(key => {
              const value = this.columnConfig()[key].value?.(row) ?? row[key];
              if (typeof value !== 'number') {
                return;
              }
              const current = acc[key] ?? 0;
              acc[key] = current + value;
            });
            return acc;
          }, {}),
        );
        this.loading.set(false);
      });
  }

  private getRowChanges(row: any): RowChanges {
    return Object.entries(this.columnConfig())
      .filter(([, config]) => config.original)
      .map(([field, config]) => {
        const from = config.original?.(row);
        const to = config.value?.(row) ?? row[field];
        const format = config.format;
        const origValue = format ? this.columnFormatService.format(from, format, config.formatOptions) : from;
        if (from !== to && origValue) {
          return { [field]: { tooltip: 'original: ' + origValue } };
        }
        return {};
      })
      .reduce((acc, v) => Object.assign(acc, v), {}) as RowChanges;
  }

  private formatExportValue(rowData: any, columnParams: ColumnParams<any>) {
    const value = columnParams.value?.(rowData) ?? rowData[columnParams.field ?? ''];
    if (columnParams.exportFormat) {
      return this.columnFormatService.format(value, columnParams.exportFormat, columnParams.formatOptions);
    }
    return this.formatValue(rowData, columnParams);
  }
}
