import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { httpGridConfig } from '@mca/shared/domain';
import { environmentToken } from '@mca/shared/util';
import { BehaviorSubject, Observable, filter, first, map, startWith, switchMap, tap, throwError } from 'rxjs';
import { ColumnsPreset, ColumnsStorageData, GridConfigStorageStrategy } from './types';

interface RemoteConfig extends Omit<ColumnsPreset, 'value'> {
  name: string;
  value: string;
}

const initialConfig: RemoteConfig[] = [];

@Injectable()
export class GridConfigStorageStrategyRemote implements GridConfigStorageStrategy {
  private httpClient = inject(HttpClient);
  private env = inject(environmentToken);

  private cachedConfig$ = new BehaviorSubject(initialConfig);

  constructor() {
    this.httpClient.get<RemoteConfig[]>(this.getUrl()).subscribe(this.cachedConfig$);
  }

  set(gridId: string, label: string, data?: Partial<ColumnsPreset>): Observable<boolean> {
    const value = data?.value && JSON.stringify(data.value);
    return this.get(gridId).pipe(
      switchMap(storageData => {
        // check if we have saved id for this label
        const presetId = String(storageData[label]?.id ?? '');
        const url = this.getUrl(presetId);
        const body = data && { name: gridId, label, preferred: data.preferred ?? false, value: value ?? '' };
        return this.makeRequest(url, presetId, body).pipe(map(() => true));
      }),
    );
  }

  get(gridId: string): Observable<ColumnsStorageData> {
    return this.cachedConfig$.pipe(
      startWith(this.cachedConfig$.value),
      filter(config => config !== initialConfig),
      map(presets => presets.filter(config => config.name === gridId)),
      map(presets =>
        presets.reduce(
          (acc, preset) => Object.assign(acc, { [preset.label ?? preset.name]: { ...preset, value: JSON.parse(preset.value) } }),
          {},
        ),
      ),
      first(),
    );
  }

  private getUrl(presetId = '') {
    return this.env.apiUrl + httpGridConfig(presetId);
  }

  private makeRequest(url: string, presetId: string, body?: Partial<RemoteConfig>) {
    if (!body) {
      // we can throw/catch custom error in future to show nice messages
      return presetId ? this.httpClient.delete(url).pipe(tap(() => this.deleteFromCache(presetId))) : throwError(() => 'Nothing to delete');
    }
    if (!presetId) {
      return this.httpClient.post<RemoteConfig>(url, body).pipe(tap(() => this.insertInCache(body as RemoteConfig)));
    }
    return this.httpClient
      .put<[{ id: number }]>(url, body)
      .pipe(tap(updated => updated.forEach(({ id }) => this.updateInCache(id ?? 0, body as Partial<RemoteConfig>))));
  }

  private deleteFromCache(id: string) {
    const value = [...this.cachedConfig$.value];
    const index = value.findIndex(v => v.id === +id);
    if (index > -1) {
      value.splice(index, 1);
      this.cachedConfig$.next(value);
    }
  }

  private updateInCache(id: number, data: Partial<RemoteConfig>) {
    const value = [...this.cachedConfig$.value];
    const index = value.findIndex(v => v.id === +id);
    if (index > -1) {
      value[index] = { ...value[index], ...data };
      this.cachedConfig$.next(value);
    }
  }

  private insertInCache(data: RemoteConfig) {
    const value = [...this.cachedConfig$.value];
    value.push(data);
    this.cachedConfig$.next(value);
  }
}
